<template>
  <div class="page page--order-canceled order-canceled">
    <div class="page__block">
      <h2>{{ $t('ordercanceled.title') }}</h2>
      <p>{{ $t('ordercanceled.text') }}</p>
      <p>{{ $t('backto') }}
        <router-link v-if="travelagent" :to="{name:'Home', query:{TALIC:talic,UID:uid,TAEMAIL:taEmail,TANAME:taName,AGENT:travelagent}}">Home</router-link>
        <router-link v-else to="/">Home</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderCanceled",
  data() {
    return {
      talic: this.$route.query.TALIC,
      uid: this.$route.query.UID,
      taEmail: this.$route.query.TAEMAIL,
      taName: this.$route.query.TANAME,
      travelagent: this.$route.query.AGENT
    }
  },

  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>